<template>
  <div class="radio" :class="{ disabled }">
    <FormKit
      :id="id"
      v-model="value"
      type="radio"
      :name="name ?? id"
      inner-class="w-[22px] h-[22px] mr-sm relative overflow-hidden shrink-0"
      :input-class="
        disabled && hasWhiteBg
          ? 'noGlobal bg-background-base cursor-no-drop'
          : 'noGlobal'
      "
      wrapper-class="flex"
      :disabled="disabled"
      :options="options"
    >
      <template v-if="$slots.label" #label="context">
        <slot name="label" :context="context" />
      </template>
      <template v-if="$slots.optionHelp" #optionHelp="context">
        <slot name="optionHelp" :context="context" />
      </template>
    </FormKit>
  </div>
</template>
<script setup lang="ts">
import type { FormKitOptionsProp } from '@formkit/inputs';
import type { PropType } from 'vue';

defineProps({
  options: {
    type: Object as PropType<FormKitOptionsProp>,
    required: true,
  },
  id: {
    type: String,
    required: false,
    default: undefined,
  },
  name: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  hasWhiteBg: {
    type: Boolean,
    default: true,
  },
});

const value = defineModel<any>();
</script>
<style lang="postcss" scoped>
.radio.disabled {
  @apply opacity-50;
}

.radio {
  :deep(input) {
    @apply w-[22px] h-[22px] border-border-light border-1 rounded-alt-sm absolute top-0 left-0 p-[4px] !cursor-inherit;
    background-color: white;
  }
  :deep(input:focus) {
    @apply outline-none ring-0;
    --tw-shadow: none;
    --tw-shadow-colored: none;
    --tw-ring: none;
    --tw-ring-shadow: none;
  }
  :deep(input:checked) {
    @apply border-border-light border-1;
    background-color: white;
    background-size: 0px;
    &::after {
      content: '';
      width: 14px;
      height: 14px;
      background-color: var(--thm-primary-base);
      @apply rounded-alt-xs absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2;
    }
  }
  :deep(li) {
    @apply my-sm !cursor-pointer;
    label {
      @apply !cursor-inherit;
    }
  }
  :deep(.formkit-option-help) {
    @apply ml-[38px] text-sm;
  }
  :deep(.formkit-custom-radio-label-hint) {
    @apply ml-2xs text-sm text-text-light self-center;
  }
  :deep([data-disabled='true']) {
    @apply !cursor-default;
    .formkit-wrapper > .formkit-inner {
      @apply opacity-50;
    }
    .formkit-wrapper > .custom-label {
      @apply opacity-50;
    }
  }
  :deep(.formkit-inner:has(input:disabled) + div) {
    @apply opacity-50;
  }
}
</style>
